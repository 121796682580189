
@font-face {
  font-family: 'Raleway';
  src: url(../assets/fonts/Raleway.ttf);
}

*{
  margin: 0;
  padding: 0;
  font-family: 'Raleway';
  color:black;
}

html{
  font-size: 200%;
}
/* Estilo container inicial */
.fill-window {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  position:relative;
}
  
/* Estilo cabecera estado conexión */
.header{
  display: none;
}

.footer{
  width:100%;
  height:113px;
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  box-shadow: 0px -4px 4px 0px rgba(0, 0, 0, 0.15);
  display:flex;
  justify-content: space-between;
  align-items: center;
  padding:20px 64px;
}

.footer > img{
  width:160px;
  height:auto;
}

.footer > span {
  font-size: 2rem;
  display:flex;
  align-items: center;
}

.footer > span > img{
  width:2rem;
  margin-right:10px;
}

.container-center{
  max-width:100%;
  width:100%;
  height: calc(100vh - 113px);
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  padding:0 5rem;
}
.date{
  text-transform: capitalize;
  width:100%;
  display:flex;
  justify-content: space-between;
  align-items: center;
  height:8rem;
}

.date > p{
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 1rem;
}

.date.border-success{
  border-bottom:2px solid #17CF63;
}

.date.border-danger{
  border-bottom:2px solid #F43E3E;
}

.card{
  width:100%;
  height:calc(100vh - 113px);
  display: flex;
  justify-content: space-between;
  align-items: center;  
  border:none;
  flex-direction:row;
}

.card-left{
  display:flex;
  flex-direction:column;
}

.card-left > .card-left-row{
  display:flex;
  align-items: center;
  margin-bottom:4rem;
}

.card-left > .card-left-row img{
  width:5rem;
  height:auto;
  margin-right:2rem;
}

.card-left > .card-left-row p{
  font-size: 3.5rem;
  margin:0;
}

.card-right{
  margin-right:5rem;
}
.percent{
  position: relative;
  z-index: 1000;
}

.percent .number{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: transparent;
}

.percent .number img{
  width:10rem;
  height:auto;
}

.percent .number h2{
  font-weight: 700;
  font-size: 5rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  color:black;
}

.text{
  position: relative;
  color: white;
  font-size: 5vh;
}
  
svg{
  position: relative;
  z-index: 1000;
  transform: rotate(-90deg);
}

svg circle{
  fill: none;
  stroke: #e4e0e0;
  stroke-width: 10;
  stroke-linecap: round;
  transform: translate(20px, 20px);
}

svg circle:last-child{
  stroke-width: 25;
}
/*
.card:nth-child(1) svg circle:nth-child(2){
  stroke-dashoffset: calc(440 - (440 * 24/100));
} */


/* Estilos Progress Bar */
.color-progress-1{
  stroke: #74E13D;
}

.color-progress-2{
  stroke: #FFFB00;
}

.color-progress-3{
  stroke: #FFA200;
}

.color-progress-4{
  stroke: #ff0000;
} 

/* Estilos Percentage */
.color-percentage-1{
  color: #74E13D !important;
}

.color-percentage-2{
  color: #FFFB00 !important;
}

.color-percentage-3{
  color: #FFA200 !important;
}

.color-percentage-4{
  color: #ff0000 !important;
} 

/*
  RESPONSIVE
*/

@media (max-width:2560px){
  html{
    font-size: 100%;
  }
}

@media (max-width:1080px){
  .card-right{
    margin-right:0 ;
  }
}

@media (max-width:920px){
  html{
    font-size: 70%;
  }
  .header{
    text-align: center;
    height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 4px 0px #0000001A;
  }

  .header img{
    width:1rem;
    margin-right:10px;
  }

  .footer{
    height:75px;
    justify-content:center;
    box-shadow:none;
  }

  .footer span{
    display:none;
  }

  .footer img{
    width: 85px;
  }

  .container-center{
    height: calc(100vh - 40px - 75px);
    padding: 0 15px;
  }

  .card{
    height: calc(100vh - 40px - 75px);
    flex-direction:column;
    padding-top:2rem;
    justify-content: flex-start;
  }

  .card-left{
    width:100%;
  }

  .card-left > .card-left-row img{
    width:2rem;
    margin-right:1rem;
  }

  .card-left > .card-left-row p{
    font-size: 2rem;
  }

  .card-right{
    margin-top:6rem;
  }

  .percent .number img{
    width:6rem;
  }
  .percent .number h2{
    font-size:3rem;
  }
}

@media (max-width:920px) and (orientation: landscape){
  .card{
    flex-direction:row;
    padding-top: 0;
  }

  .card-right{
    margin-top: 0;
    margin-right:4rem;
  }
}
