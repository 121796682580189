  /* Estilo container inicial */
  .fill-window {
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    background-color: black;}
  
  /* Estilo cabecera estado conexión */
  .header{
    text-align: center;
    height: 10%;
    font-size: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;}

  /* Contenedor de Aforo */
  .container-center{
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;}

  .container-aforo{
    width: 70%;
    background-color: rgba(122, 122, 122, 0.322);    
    text-align: center;
    padding: 3%;
    font-size: 6vw;
    border: 1px solid #000000;
    border-radius: 20px;}  

  /* Estilos Progress Bar */  
  .progress{
    height: 35px!important;
    margin-top: 50px;}

  /* Estilos Progress Bar por aforo */
  .bg-count-1{
    background-color: #74E13D!important;
    color: black!important;
    font-weight: bold!important;    }

  .bg-count-2{
    background-color: #FFFB00!important;
    color: black!important;
    font-weight: bold!important;}

  .bg-count-3{
    background-color: #FFA200!important;
    color: black!important;
    font-weight: bold!important;}

  .bg-count-4{
    background-color: #FF0000!important;
    color: black!important;
    font-weight: bold!important;}

  /* Estilos texto de aforo */
  .tx-count-1{
    color: #74E13D!important;
    font-weight: bold!important;}

  .tx-count-2{
    color: #FFFB00!important;
    font-weight: bold!important;}

  .tx-count-3{
    color: #FFA200!important;
    font-weight: bold!important;}

  .tx-count-4{
    color: #FF0000!important;
    font-weight: bold!important;}

  /* Otros estilos */
  .white{
    color: white;}

  .rigth{
    text-align: right;}

  .left{
    text-align: left;}